function Social () {
    return (
        <ul className="pbmit-social-links">
            <li className="pbmit-social-li pbmit-social-facebook">
                <a title="Facebook" href="https://www.facebook.com/igoglobalofficial?mibextid=ZbWKwL" target="blank" rel="noopener">
                    <span><i className="pbmit-base-icon-facebook-squared"></i></span>
                </a>
            </li>
            <li className="pbmit-social-li pbmit-social-twitter">
                <a title="Twitter" href="https://x.com/igoglobal_inc" target="blank" rel="noopener">
                    <span><i className="pbmit-base-icon-twitter"></i></span>
                </a>
            </li>
            <li className="pbmit-social-li pbmit-social-instagram">
                <a title="Instagram" href="https://www.instagram.com/igoglobal_inc/" target="blank" rel="noopener">
                    <span><i className="pbmit-base-icon-instagram"></i></span>
                </a>
            </li>
        </ul>
    )
}

export default Social;