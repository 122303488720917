function FooterContact () {
    return (
        <div className="col-md-4 pbmit-footer-widget-col-2">
            <div className="widget">
                <h2 className="widget-title">Contact Us</h2>
                <div className="pbmit-contact-widget-lines">
                    <div className="pbmit-contact-widget-phone">
                        +(234) 903 - 979 - 9817
                    </div>
                    <div className="pbmit-contact-widget-email">
                        info@igoglobal.io
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FooterContact;