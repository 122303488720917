function ContactInbox () {
    return (
        <section className="ihbox-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 col-xl-3 pbmit-col-wrapper">
                        <div className="pbmit-ihbox-style-2">
                            <div className="pbmit-ihbox-icon">
                                <div className="pbmit-ihbox-icon-wrapper">
                                    <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                        <i className="pbmit-capigo-icon pbmit-capigo-icon-email-1"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox-contents">
                                <h2 className="pbmit-element-title">
                                    Mail us 24/7
                                </h2>
                                <div className="pbmit-heading-desc">myigoglobal.com <br></br> info@igoglobal.io</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 pbmit-col-wrapper">
                        <div className="pbmit-ihbox-style-2">
                            <div className="pbmit-ihbox-icon">
                                <div className="pbmit-ihbox-icon-wrapper">
                                    <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                        <i className="pbmit-capigo-icon pbmit-capigo-icon-location-1"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox-contents">
                                <h2 className="pbmit-element-title">
                                    Our Location
                                </h2>
                                <div className="pbmit-heading-desc">1, Morenikeji Street, Off Ladipo Kasimu Street, Allen Avenue - Ikeja Lagos State, Nigeria </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 pbmit-col-wrapper">
                        <div className="pbmit-ihbox-style-2">
                            <div className="pbmit-ihbox-icon">
                                <div className="pbmit-ihbox-icon-wrapper">
                                    <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                        <i className="pbmit-capigo-icon pbmit-capigo-icon-phone-call"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox-contents">
                                <h2 className="pbmit-element-title">
                                    Call us 24/7
                                </h2>
                                <div className="pbmit-heading-desc">Phone: +234 903-979-9817 <br></br> Mobile: +234 903-979-9817</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 pbmit-col-wrapper">
                        <div className="pbmit-ihbox-style-2">
                            <div className="pbmit-ihbox-icon">
                                <div className="pbmit-ihbox-icon-wrapper">
                                    <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                        <i className="pbmit-capigo-icon pbmit-capigo-icon-calendar"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="pbmit-ihbox-contents">
                                <h2 className="pbmit-element-title">
                                    Working Days
                                </h2>
                                <div className="pbmit-heading-desc">Mon to Fri - 08:00am To 06:00pm <br></br> Saturday to Sunday - Closed</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    )
}

export default ContactInbox