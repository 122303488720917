import ProductInfo from "./ProductInfo";

function Iroko () {
    return (
        <article className="pbmit-portfolio-single">
            <ProductInfo 
                img="" title="Iroko" category="Health" date="06 April, 2024" 
                p1=""
                p2="Iroko African Root is a unique and powerful blend of traditional medicinal plants, each contributing a range of health benefits. Here’s why you should consider adding Iroko African Root to your wellness regimen" 
                p3="Iroko African Root stands as a testament to the power of nature in promoting health and wellness. With its rich blend of the Iroko Tree, Mahogany, Zingiber Officinale (Ginger), Clove, and Hunteria Umbellata, it offers a comprehensive solution to a wide range of health issues. Whether you seek to prevent infections, reduce inflammation, support digestive health, or enhance respiratory function, Iroko African Root is your natural remedy of choice. Embrace the holistic benefits of Iroko African Root and experience the profound impact of nature’s healing power on your health and well-being"
                
            
                // img1="irk1.avif" img2="irk3.avif" img3="irk2.avif"
                img1="iroko_tile1.png" img2="iroko_tile2.png" img3="iroko_tile3.png"
                
                benefits="The Iroko Tree, often revered in African folklore for its strength and longevity, brings several health benefits to the Iroko African Root blend. It is renowned for its antimicrobial properties, effectively combating bacteria, fungi, and viruses. 
                    This makes it an excellent natural remedy for preventing and treating infections. Additionally, the Iroko Tree’s antifungal properties are particularly effective against common fungal infections such as athlete’s foot and ringworm.
                    The diverse components of Iroko African Root offer a wide array of health benefits, addressing everything from infections and inflammation to digestive and respiratory health."
                b1="Sourced from natural ingredients, Iroko African Root provides a safe and effective alternative to synthetic medications, with fewer side effects" 
                b2="Iroko African Root promotes holistic health, supporting multiple bodily systems and enhancing overall well-being"
                b3="The components of Iroko African Root have been used for centuries in traditional medicine, backed by generations of empirical knowledge and experience"
                b4="With Hunteria Umbellata’s anti-cancer properties, Iroko African Root offers a natural defense against cancer, contributing to long-term health"

                ingImg="iroko_ing.png" benefitImg="iroko_benefit.png"
                defImg="iroko_def.png"
                linkPrev="#" linkNext="pure-virgin" titlePrev="Nil" titleNext="Pure Virgin"
                
                ing1="👨‍🍳 Mahogany => It effectively reduces swelling and pain, making it a natural choice for managing chronic inflammatory conditions. Its antiseptic properties help prevent infections and promote cleanliness, making it ideal for wound care and skin health."
                ing2="👨‍🍳 Zingiber Officinale (Ginger) => Ginger serves as a digestive aid, relieving nausea, indigestion, and bloating. Its anti-inflammatory properties reduce pain and swelling, making it effective for conditions like arthritis and muscle soreness."
                ing3="👨‍🍳 Clove => This is a well-known spice with potent medicinal properties, and its analgesic properties provide effective relief from toothache pain and headaches, offering a natural alternative to over-the-counter painkillers"
                ing4="👨‍🍳 Hunteria Umbellata => The antioxidant properties of Hunteria Umbellata protect against cell damage and aging, promoting longevity and overall well-being. Its anti-inflammatory properties also helps reduce pain and swelling, making it effective for managing chronic inflammatory conditions."
            />
            
        </article>
    )
}

export default Iroko;