import FAQ from "./FAQ";

function ServicesFaq () {
    return (
        <>
        <div className="pbmit-heading-subheading animation-style2">
            <h3 className="pbmit-title">Frequently Asked Question</h3>
            </div>
            <p>Igoglobal International Inc. (IGOGLOBAL REPUBLIC) is an indigenous direct-selling conglomerate that promotes African herbal products and other solutions in health & wellness, personal care, real estate, education, and technology. The company operates with a grassroots business model and offers a revolutionary reward plan to its members.</p>
            <div className="accordion" id="accordionExample1">
                <FAQ 
                    id="headingTwo1"
                    collapse="collapseTwo1"
                    num="01."
                    qst="What types of products does Igoglobal International Inc. offer?"
                    ans="We offer health and wellness products designed for both prevention and cure. Notable products include Iroko African Root and Pure Virgin, each with specific health benefits aimed at improving overall well-being."
                />
                <FAQ 
                    id="headingThree1"
                    collapse="collapseThree1"
                    num="02."
                    qst="What makes Igoglobal International Inc.'s business model unique?"
                    ans="Our business model is unique due to its grassroots approach, which focuses on empowering individuals at the community level. The company combines the benefits of direct selling with a revolutionary reward plan, ensuring that members can earn income not just from sales but also from referrals and product consumption."
                />
                <FAQ 
                    id="headingFour1"
                    collapse="collapseFour1"
                    num="03."
                    qst="Are there any membership fees to join Igoglobal International Inc.?"
                    ans="Yes, there is a membership fee to join Igoglobal International Inc. This fee grants access to the company's products at a discounted rate, the ability to earn points and referral bonuses, and participation in training and support programs. Specific details about the membership fee can be found on the company's official compensation plan."
                />
                <FAQ 
                    id="headingFive1"
                    collapse="collapseFive1"
                    num="04."
                    qst="Can I earn income from Igoglobal International Inc. without selling products?"
                    ans="Yes, you can earn income from Igoglobal International Inc. without selling products. The company’s compensation plan allows members to earn points and referral bonuses through product purchases and by referring new members. While selling products can increase earnings, it is not mandatory for income generation."
                />
                <FAQ 
                    id="headingSix1"
                    collapse="collapseSix1"
                    num="05."
                    qst="How can I track my earnings and points in Igoglobal International Inc.?"
                    ans="Members can track their earnings and points through the Igoglobal International Inc. member portal. Upon logging in, members have access to their personal dashboard, which provides detailed information on points earned from purchases, referral bonuses, and other income. The portal is designed to be user-friendly and provides real-time updates."
                />
                <FAQ 
                    id="headingSeven1"
                    collapse="collapseSeven1"
                    num="06."
                    qst="How does Igoglobal International Inc. give back to the community?"
                    ans="Igoglobal International Inc. is committed to giving back to the community through various initiatives. These include health awareness programs, educational workshops, and charitable donations. The company regularly organizes events and activities that support community development and well-being. Members are encouraged to participate in these initiatives to help make a positive impact."
                />

            </div>
        </>
    )
}

export default ServicesFaq;