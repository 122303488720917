function Solution () {
    return (
      <section className="section-lgx">
        <div className="container">
          <div className="row g-0 align-items-center">
            <div className="col-md-12 col-xl-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="about-three_img1">
                    {/* <div className="pbmit-animation-style4"> */}
                    <div className="">
                      <img
                        src="https://igogolobal-assets.s3.eu-west-2.amazonaws.com/images/solution.avif"
                        className="img-fluid"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-three_img2">
                    {/* <div className="pbmit-animation-style3"> */}
                    <div className="">
                      <img
                        src="https://igogolobal-assets.s3.eu-west-2.amazonaws.com/images/happyman.avif"
                        className="img-fluid"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xl-6">
              <div className="about-us-three-rightbox">
                <div className="pbmit-heading-subheading animation-style2">
                  <h4 className="pbmit-subtitle">igoglobal helps</h4>
                  <h2 className="pbmit-title">
                    Provide the best health and wellness products
                  </h2>
                </div>
                <div className="about-us-three-content">
                  <div className="pbmit-desc">
                    Our approach at Igoglobal breaks the mold of traditional
                    industry standards by recognizing that everyone is unique,
                    with diverse backgrounds, tastes, and aspirations. Igoglobal
                    is dedicated to embracing your individuality and unlocking
                    your exceptional potential.{" "}
                  </div>
                  <div className="row mt-5 mb-5">
                    <div className="col-md-6">
                      <div className="pbmit-ihbox-style-2">
                        <div className="pbmit-ihbox-icon">
                          <div className="pbmit-ihbox-icon-wrapper">
                            <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                              <i className="pbmit-capigo-icon pbmit-capigo-icon-call-center"></i>
                            </div>
                          </div>
                        </div>
                        <div className="pbmit-ihbox-contents">
                          <h2 className="pbmit-element-title">
                            Customer Assistance
                          </h2>
                          <div className="pbmit-heading-desc">
                            We have the best hands to help attend to all your
                            various needs, don't hesitate to reach out to us
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pbmit-ihbox-style-2">
                        <div className="pbmit-ihbox-icon">
                          <div className="pbmit-ihbox-icon-wrapper">
                            <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                              <i className="pbmit-capigo-icon pbmit-capigo-icon-investment"></i>
                            </div>
                          </div>
                        </div>
                        <div className="pbmit-ihbox-contents">
                          <h2 className="pbmit-element-title">
                            Improved health
                          </h2>
                          <div className="pbmit-heading-desc">
                            Our various natural extracted products are
                            guaranteed to provide improved health.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Solution;