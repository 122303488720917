function Drive () {
    return (
        <section className="section-xl fid-three">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="pbminfotech-ele-fid-style-3 pe-xxl-5">
                            <div className="pbmit-fld-contents">
                                <div className="pbmit-circle-outer" data-digit="95" data-fill="#03251e" data-emptyfill="#f5f2f0" data-before="" data-after="<sup>%</sup>" data-thickness="2" data-size="130">
                                    <div className="pbmit-circle">
                                        <div className="pbmit-fid-inner">
                                            <span className="pbmit-fid-number"></span>
                                            <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="95" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">95</span>
                                            <sup>%</sup>			
                                        </div>
                                    </div>
                                    <div className="pbmit-fid-sub">
                                        <h3 className="pbmit-fid-title">Finalized projects</h3>
                                        <div className="pbmit-heading-desc">Both Iroko and Pure Virgin have thousands of testimonies.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="pbminfotech-ele-fid-style-3 pe-xxl-5">
                            <div className="pbmit-fld-contents">
                                <div className="pbmit-circle-outer" data-digit="75" data-fill="#03251e" data-emptyfill="#f5f2f0" data-before="" data-after="<sup>%</sup>" data-thickness="2" data-size="130">
                                    <div className="pbmit-circle">
                                        <div className="pbmit-fid-inner">
                                            <span className="pbmit-fid-number"></span>
                                            <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="75" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">75</span>
                                            <sup>%</sup>			
                                        </div>
                                    </div>
                                    <div className="pbmit-fid-sub">
                                        <h3 className="pbmit-fid-title">New Products</h3>
                                        <div className="pbmit-heading-desc">We hire the best of herbal specialists concucting our products.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="pbminfotech-ele-fid-style-3 pe-xxl-5">
                            <div className="pbmit-fld-contents">
                                <div className="pbmit-circle-outer" data-digit="90" data-fill="#03251e" data-emptyfill="#f5f2f0" data-before="" data-after="<sup>%</sup>" data-thickness="2" data-size="130">
                                    <div className="pbmit-circle">
                                        <div className="pbmit-fid-inner">
                                            <span className="pbmit-fid-number"></span>
                                            <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="90" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">90</span>
                                            <sup>%</sup>			
                                        </div>
                                    </div>
                                    <div className="pbmit-fid-sub">
                                        <h3 className="pbmit-fid-title">Priority of Goals</h3>
                                        <div className="pbmit-heading-desc">Our staff are very committed to every task at hand.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Drive;