function About () {
    return (
        <section className="section-lgx">
        <div className="container">
            <div className="row align-items-center g-0">
                <div className="col-md-3 col-xl-2">
                    <div className="fid-style-7-area">
                        <div className="pbminfotech-ele-fid-style-7">
                            <div className="pbmit-fld-contents">
                                <h3 className="pbmit-fid-title">Happy Clients</h3>
                                <h4 className="pbmit-fid-inner">
                                    <span className="pbmit-fid"></span>
                                    <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="2000" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">2000</span>
                                    <span className="pbmit-fid"><span>+</span></span>
                                </h4>
                            </div>
                        </div>
                        <div className="pbminfotech-ele-fid-style-7">
                            <div className="pbmit-fld-contents">
                                <h3 className="pbmit-fid-title">Total Employees</h3>
                                <h4 className="pbmit-fid-inner">
                                    <span className="pbmit-fid"></span>
                                    <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="25" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">25</span>
                                    <span className="pbmit-fid"><span>+</span></span>
                                </h4>
                            </div>
                        </div>
                        <div className="pbminfotech-ele-fid-style-7">
                            <div className="pbmit-fld-contents">
                                <h3 className="pbmit-fid-title">Useful Programs</h3>
                                <h4 className="pbmit-fid-inner">
                                    <span className="pbmit-fid"></span>
                                    <span className="pbmit-number-rotate numinate" data-appear-animation="animateDigits" data-from="0" data-to="6" data-interval="5" data-before="" data-before-style="" data-after="" data-after-style="">6</span>
                                    <span className="pbmit-fid"><span>+</span></span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 col-xl-5">
                    <img src="https://igogolobal-assets.s3.eu-west-2.amazonaws.com/images/black-girl.avif" className="img-fluid" alt=""></img>
                </div>
                <div className="col-md-12 col-xl-5">
                    <div className="about-us-one-content">
                        <div className="pbmit-heading-subheading">
                            <h4 className="pbmit-subtitle">Who we are</h4>
                            <h2 className="pbmit-title"> Improving African health</h2>
                        </div>
                        <div className="pbmit-ihbox-style-9">
                            <div className="pbmit-ihbox-wapper">
                                <div className="pbmit-ihbox-icon">
                                    <div className="pbmit-ihbox-icon-wrapper">
                                        <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                            <i className="ti-check"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="pbmit-ihbox-wapper-inner">
                                    <h2 className="pbmit-element-title">
                                        Health and wellness
                                    </h2>
                                    <div className="pbmit-heading-desc">
                                        Harnessing the power of traditional African herbal medicine, we offer a diverse range of wellness products to address contemporary health challenges.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pbmit-ihbox-style-9 mt-5">
                            <div className="pbmit-ihbox-wapper">
                                <div className="pbmit-ihbox-icon">
                                    <div className="pbmit-ihbox-icon-wrapper">
                                        <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                            <i className="ti-check"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="pbmit-ihbox-wapper-inner">
                                    <h2 className="pbmit-element-title">
                                        Real Estate
                                    </h2>
                                    <div className="pbmit-heading-desc">
                                        Engaged in the development and sale of premium real estate projects, contributing to sustainable urban development.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default About;