import ProductInfo from "./ProductInfo";

function PureVirgin () {
    return (
        <article className="pbmit-portfolio-single">
            <ProductInfo 
                img="" title="Pure Virgin" category="Wellness" date="21 June, 2024" 
                p1=""
                p2="Yeast infections and vaginal dryness are common issues that many women face, often leading to discomfort and embarrassment. These conditions can significantly impact a woman’s quality of life, causing itching, burning, and irritation." 
                p3="Pure Virgin is specifically formulated to address these concerns with the power of natural ingredients that have been trusted for centuries."
                
                // img1="pv1.avif" img2="pv2.avif" img3="pv3.avif"
                img1="pv-test3.avif" 
                // img1="pv_tile1.png" 
                // img2="pv_tile2.png" // useful
                img2="pv-test4.avif" 
                img3="pv_tile3.png"
                
                benefits="Pure Virgin is derived from nature’s finest elements, ensuring that every application is safe, gentle, and effective. We understand the importance of using natural ingredients for feminine care, and Pure Virgin embodies this commitment by combining traditional knowledge with modern science."
                
                b1="Pure Virgin is specifically designed to treat unpleasant discharge effectively. Its powerful blend of natural ingredients targets the root causes of abnormal discharge, providing relief and restoring normalcy." 
                b2="The unique formulation of Pure Virgin helps tighten and cleanse the vaginal area. The natural tightening properties of Cam Wood combined with the cleansing effects of Aloe Vera and Cocoa Pod Soup ensure optimal vaginal health and hygiene"
                b3="Beyond addressing physical symptoms, Pure Virgin helps revitalize libido. The natural ingredients work synergistically to restore confidence and vitality, enhancing overall well-being and sexual health"
                b4="Pure Virgin promotes optimal hygiene and freshness, ensuring that women feel comfortable and confident throughout the day. The antiseptic and moisturizing properties keep the vaginal area clean, hydrated, and free from infections."
                
                ingImg="pv_ing.png" benefitImg="pv_benefit.avif" defImg=""
                linkPrev="iroko" linkNext="#" titlePrev="Iroko" titleNext="Nil"

                ing1="👩‍🍳 Aloe Vera :: Renowned for its soothing and healing properties, it helps to reduce inflammation, soothe itching, and promote the healing of vaginal tissues."
                ing2="👩‍🍳 Cocoa Pod Soup :: Rich in antioxidants and anti-inflammatory compounds, it plays a crucial role in reducing inflammation and itching associated with yeast infections."
                ing3="👩‍🍳 Hyssop :: A herb well-known for its potent antifungal properties and also supports the natural microbial environment of the vagina, reducing the likelihood of recurrent infections."
                ing4="👩‍🍳 Cam Wood :: A natural antiseptic that helps cleanse the vaginal area, significantly reducing the risk of infections. It also has a natural tightening effect, which can enhance the tone and elasticity of the vaginal tissues, providing a rejuvenated feeling."
            />
        </article>
    )
}

export default PureVirgin;